<template>
  <div class="container kuan">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain type-lhc twlh game-twlh game-lhc-zm6">
        <div v-for="(item,index) in rowsData" class="data" :class="'cZ' + (index+1)" :key="item.title">
          <h3>{{item.title}}</h3>
          <ul>
            <li v-for="ele in item.arr" :key="ele.ResultID" :class="ele.selected ? 'selected' : ''" @click="setCurrentSelected(ele)">
              <ol style="width: 60px;min-width:40px" class="td_name">
                {{
                  ele.label
                }}
              </ol>
              <ol style="width: 60px;min-width:43px" class="td_rate">
                {{
                 ele.Odds
                }}
              </ol>
              <ol class="td_cash">
                <input type="text" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li style="width:40px" v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
            <li class="lidsdx">
              <span @click="selkuanxian(index,'单')">单</span>
              <span @click="selkuanxian(index,'双')">双</span>
              <span @click="selkuanxian(index,'大')">大</span>
              <span @click="selkuanxian(index,'小')">小</span>
            </li>
          </ul>
        </div>
      </div>
      <yushe />
    </div>

  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengma16Data } from "../data";
import mixins from "../mixins/mainMixins";
export default {
  name: "yiziding",
  components: { topTimer, yushe, yanzheng },
  mixins: [mixins],
  props: [""],
  data() {
    return {
      groupnames:'yzd',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
        {
          title: "口XXXX",
          arr: [],
        },
        {
          title: "X口XXX",
          arr: [],
        },
        {
          title: "XX口XX",
          arr: [],
        },
        {
          title: "XXX口X",
          arr: [],
        },
        {
          title: "XXXX口",
          arr: [],
        },
      ],
 
    };
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        let labelArr1 = [],
          labelArr2 = [],
          labelArr3 = [],
          labelArr4 = [],
          labelArr5 = [];
        for (let index = 0; index < 10; index++) {
          let label1 = index + "XXXX";
          let label2 = "X" + index + "XXX";
          let label3 = "XX" + index + "XX";
          let label4 = "XXX" + index + "X";
          let label5 = "XXXX" + index;
          labelArr1.push(label1);
          labelArr2.push(label2);
          labelArr3.push(label3);
          labelArr4.push(label4);
          labelArr5.push(label5);
        }
        //  仟
        let arr1 = this.filterData(resAArr, 5459, 5468, labelArr1);
        this.rowsData[0].arr = arr1;
        // 佰
        let arr2 = this.filterData(resAArr, 5469, 5478, labelArr2);
        this.rowsData[1].arr = arr2;
        // 拾
        let arr3 = this.filterData(resAArr, 5479, 5488, labelArr3);
        this.rowsData[2].arr = arr3;
        // 个
        let arr4 = this.filterData(resAArr, 5489, 5498, labelArr4);
        this.rowsData[3].arr = arr4;
        // 五
        let arr5 = this.filterData(resAArr, 5499, 5508, labelArr5);
        this.rowsData[4].arr = arr5;

        this.$forceUpdate();
      },
    },
  },
  created() {
     
  },

  methods: {
    selkuanxian(num, str) {
      this.rowsData[num].arr.forEach((ele, index) => {
        if (str == "单") {
          if (index % 2 != 0) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        }
        if (str == "双") {
          if (index % 2 == 0) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        }
        if (str == "大") {
          if (index >= 5) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        }
        if (str == "小") {
          if (index < 5) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        }
      });
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    // input聚焦 判断显示快速下注
  },
};
</script>
<style scoped>
li.lidsdx {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
li.lidsdx span {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #f7f7f7;
  border: 1px solid #999;
  text-align: center;
  line-height: 24px;
  margin: 0 2px;
  border-radius: 5px;
}
.listmain > .data > ul > li .td_cash input {
  /* width: 32px !important; */
}

.listmain.game-lhc-zm6 .data {
  margin-right: 1px;
  width: calc((100% - 5px) / 5);
}
</style>
